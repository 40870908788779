<template>
  <div class="relative h-screen pt-24 pb-6 lg:pb-10 flex flex-col items-center justify-end">
    <div
      class="absolute top-0 left-o w-full h-full"
      @click="toggle"
    >
      <video
        ref="video"
        :src="explore.video"
        :poster="videoPoster"
        class="absolute top-0 left-0 w-full h-full object-cover"
      />
    </div>

    <div
      v-if="showOverlay"
      class="absolute top-0 left-0 w-full h-full bg-black-300"
    >
      <button
        ref="btnPlay"
        class="btn-play btn-play--lg
        absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        @click="play"
      />
    </div>

    <div class="relative z-10 px-4 2xl:px-8">
      <button
        class="border border-blue rounded-full py-2 lg:py-6 px-5 lg:px-12
      bg-blue text-sm lg:text-base font-bold text-white
        transition-colors
      hover:bg-white hover:text-blue hover:border-white
        focus:outline-none focus:bg-white focus:text-blue"
        @click="openStadium"
      >
        Explore the stadium
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getters } from '@/store';
import videoPoster from '@/assets/images/video.jpg';

export default {
  name: 'VideoPage',
  data() {
    return {
      isPlaying: false,
      showOverlay: true,
      videoPoster,
    };
  },
  computed: {
    ...mapGetters([
      getters.content.explore,
    ]),
  },
  mounted() {
    this.$nextTick(() => {
      this.play();
    });
  },
  methods: {
    play() {
      this.$refs.video.play().then(() => {
        this.isPlaying = true;
        this.showOverlay = false;
      }).catch(() => {});
    },
    pause() {
      this.$refs.video.pause();
      this.isPlaying = false;
      this.showOverlay = true;
    },
    toggle() {
      if (!this.isPlaying) {
        this.play();
      } else {
        this.pause();
      }
    },
    openStadium() {
      this.$router.push({
        name: 'stadium.index',
      });
    },
  },
};
</script>
